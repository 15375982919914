/* Mostly typography, shift to typography.js with theme */

body {
	width: 100%;
	height: 100vh;
	position: relative;
	font-family: Proxima Nova;
}

h1 {
	font-size: 1.6em;
	font-weight: 500;
}

h2 {
	font-size: 1.4em;
	font-weight: 500;
}

h3 {
	font-weight: 500;
	font-size: 1em;
}

h4 {
	font-weight: 500;
}

.iframe-container {
	position: absolute;
	width: calc(100% - 40px);
	height: 100%;
}

.report-anchor-container {
	margin-bottom: 5px;
}

.report-anchor {
	color: #007bff !important;
	cursor: pointer;
}

.report-anchor:focused {
	text-decoration: underline !important;
}

.result-powered-by {
	display: none !important;
}

/*
select {
	width: 180px;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}*/

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container {
	width: 100%;
}

.Select {
	margin: 8px 0;
}

.Select-control {
	height: 41px;
}

.Select-value-label {
	line-height: 38px;
}

.s-alert-close {
	display: none;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.woff')
		format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Light';
	src: url('./fonts/Mark Simonson - Proxima Nova Thin-webfont.woff')
		format('woff');
	font-weight: normal;
	font-style: normal;
}

.formSelector {
	margin: -11px;
	margin-left: -18px;
	height: 21px !important;
	margin-top: -9px;
}

body {
	/* font-family: 'Open Sans', sans-serif; */
	line-height: 1.25;
}

table {
	border: 1px solid #ccc;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 100%;
	table-layout: fixed;
}

table caption {
	font-size: 1.5em;
	margin: 0.5em 0 0.75em;
}

table thead tr {
	background: #f8f8f8;
}

table tr {
	border: 1px solid #ddd;
	padding: 0.35em;
}

table th,
table td {
	padding: 0.625em;
	text-overflow: ellipsis;
	text-align: left;
}

.table-cell {
	padding: 7px 5px;
}

.table-cell:hover {
	background: green;
	cursor: pointer;
}

.btn-add-right {
	float: right;
	margin-bottom: 18px;
	color: #fff !important;
	width: 80px;
	padding: 0.6em;
	border: none;
	background-color: #58ade5;
	margin: 1em 0;
	border-radius: 2px;
	cursor: pointer;
	text-align: center;
}

.btn-edit {
	float: right;
	color: #fff !important;
	width: 80px;
	padding: 0.6em;
	border: none;
	background-color: #58ade5;
	border-radius: 2px;
	cursor: pointer;
	text-align: center;
}

.inputText {
	width: 90%;
	padding: 10px 10px;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	font-family: Proxima Nova;
}

.col,
.half,
.third,
.twothird,
.threequarter,
.quarter {
	float: left;
	width: 100%;
}

.col.s1 {
	width: 8.33333%;
}

.col.s2 {
	width: 16.66666%;
}

.col.s3 {
	width: 24.99999%;
}

.col.s4 {
	width: 33.33333%;
}

.col.s5 {
	width: 41.66666%;
}

.col.s6 {
	width: 49.99999%;
}

.col.s7 {
	width: 58.33333%;
}

.col.s8 {
	width: 66.66666%;
}

.col.s9 {
	width: 74.99999%;
}

.col.s10 {
	width: 83.33333%;
}

.col.s11 {
	width: 91.66666%;
}

.col.s12 {
	width: 99.99999%;
}

@media (min-width: 601px) {
	.col.m1 {
		width: 8.33333%;
	}
	.col.m2 {
		width: 16.66666%;
	}
	.col.m3,
	.quarter {
		width: 24.99999%;
	}
	.col.m4,
	.third {
		width: 33.33333%;
	}
	.col.m5 {
		width: 41.66666%;
	}
	.col.m6,
	.half {
		width: 49.99999%;
	}
	.col.m7 {
		width: 58.33333%;
	}
	.col.m8,
	.twothird {
		width: 66.66666%;
	}
	.col.m9,
	.threequarter {
		width: 74.99999%;
	}
	.col.m10 {
		width: 83.33333%;
	}
	.col.m11 {
		width: 91.66666%;
	}
	.col.m12 {
		width: 99.99999%;
	}
}

@media (min-width: 993px) {
	.col.l1 {
		width: 8.33333%;
	}
	.col.l2 {
		width: 16.66666%;
	}
	.col.l3 {
		width: 24.99999%;
	}
	.col.l4 {
		width: 33.33333%;
	}
	.col.l5 {
		width: 41.66666%;
	}
	.col.l6 {
		width: 49.99999%;
	}
	.col.l7 {
		width: 58.33333%;
	}
	.col.l8 {
		width: 66.66666%;
	}
	.col.l9 {
		width: 74.99999%;
	}
	.col.l10 {
		width: 83.33333%;
	}
	.col.l11 {
		width: 91.66666%;
	}
	.col.l12 {
		width: 99.99999%;
	}
}

@media screen and (max-width: 600px) {
	table {
		border: 0;
	}
	table caption {
		font-size: 1.3em;
	}
	table thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	table tr {
		border-bottom: 3px solid #ddd;
		display: block;
		margin-bottom: 0.625em;
	}
	table td {
		border-bottom: 1px solid #ddd;
		display: block;
		font-size: 0.8em;
		text-align: right;
	}
	table td:before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	}
	table td:last-child {
		border-bottom: 0;
	}
}

/*.btn, .button {
	border: none;
	display: inline-block;
	outline: 0;
	padding: 8px 16px;
	vertical-align: middle;
	overflow: hidden;
	text-decoration: none;
	color: inherit;
	background-color: inherit;
	text-align: center;
	cursor: pointer;
	white-space: nowrap
}

.btn:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.btn, .button {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}*/

.disabled,
.btn:disabled,
.button:disabled {
	cursor: not-allowed;
	opacity: 0.3;
}

.disabled *,
:disabled * {
	pointer-events: none;
}

.btn.disabled:hover,
.btn:disabled:hover {
	box-shadow: none;
}

/* Colors */

.amber,
.hover-amber:hover {
	color: #000 !important;
	background-color: #ffc107 !important;
}

.aqua,
.hover-aqua:hover {
	color: #000 !important;
	background-color: #00ffff !important;
}

.blue,
.hover-blue:hover {
	color: #fff !important;
	background-color: #2196f3 !important;
}

.light-blue,
.hover-light-blue:hover {
	color: #000 !important;
	background-color: #87ceeb !important;
}

.brown,
.hover-brown:hover {
	color: #fff !important;
	background-color: #795548 !important;
}

.cyan,
.hover-cyan:hover {
	color: #000 !important;
	background-color: #00bcd4 !important;
}

.blue-grey,
.hover-blue-grey:hover,
.blue-gray,
.hover-blue-gray:hover {
	color: #fff !important;
	background-color: #607d8b !important;
}

.green,
.hover-green:hover {
	color: #fff !important;
	background-color: #4caf50 !important;
}

.light-green,
.hover-light-green:hover {
	color: #000 !important;
	background-color: #8bc34a !important;
}

.indigo,
.hover-indigo:hover {
	color: #fff !important;
	background-color: #3f51b5 !important;
}

.khaki,
.hover-khaki:hover {
	color: #000 !important;
	background-color: #f0e68c !important;
}

.lime,
.hover-lime:hover {
	color: #000 !important;
	background-color: #cddc39 !important;
}

.orange,
.hover-orange:hover {
	color: #000 !important;
	background-color: #ff9800 !important;
}

.deep-orange,
.hover-deep-orange:hover {
	color: #fff !important;
	background-color: #ff5722 !important;
}

.pink,
.hover-pink:hover {
	color: #fff !important;
	background-color: #e91e63 !important;
}

.purple,
.hover-purple:hover {
	color: #fff !important;
	background-color: #9c27b0 !important;
}

.deep-purple,
.hover-deep-purple:hover {
	color: #fff !important;
	background-color: #673ab7 !important;
}

.red,
.hover-red:hover {
	color: #fff !important;
	background-color: #f44336 !important;
}

.sand,
.hover-sand:hover {
	color: #000 !important;
	background-color: #fdf5e6 !important;
}

.teal,
.hover-teal:hover {
	color: #fff !important;
	background-color: #009688 !important;
}

.yellow,
.hover-yellow:hover {
	color: #000 !important;
	background-color: #ffeb3b !important;
}

.white,
.hover-white:hover {
	color: #000 !important;
	background-color: #fff !important;
}

.black,
.hover-black:hover {
	color: #fff !important;
	background-color: #000 !important;
}

.grey,
.hover-grey:hover,
.gray,
.hover-gray:hover {
	color: #000 !important;
	background-color: #bbb !important;
}

.light-grey,
.hover-light-grey:hover,
.light-gray,
.hover-light-gray:hover {
	color: #000 !important;
	background-color: #f1f1f1 !important;
}

.dark-grey,
.hover-dark-grey:hover,
.dark-gray,
.hover-dark-gray:hover {
	color: #fff !important;
	background-color: #616161 !important;
}

.pale-red,
.hover-pale-red:hover {
	color: #000 !important;
	background-color: #ffdddd !important;
}

.pale-green,
.hover-pale-green:hover {
	color: #000 !important;
	background-color: #ddffdd !important;
}

.pale-yellow,
.hover-pale-yellow:hover {
	color: #000 !important;
	background-color: #ffffcc !important;
}

.pale-blue,
.hover-pale-blue:hover {
	color: #000 !important;
	background-color: #ddffff !important;
}

.text-red,
.hover-text-red:hover {
	color: #f44336 !important;
}

.text-green,
.hover-text-green:hover {
	color: #4caf50 !important;
}

.text-blue,
.hover-text-blue:hover {
	color: #2196f3 !important;
}

.text-yellow,
.hover-text-yellow:hover {
	color: #ffeb3b !important;
}

.text-white,
.hover-text-white:hover {
	color: #fff !important;
}

.text-black,
.hover-text-black:hover {
	color: #000 !important;
}

.text-grey,
.hover-text-grey:hover,
.text-gray,
.hover-text-gray:hover {
	color: #757575 !important;
}

.text-amber {
	color: #ffc107 !important;
}

.text-aqua {
	color: #00ffff !important;
}

.text-light-blue {
	color: #87ceeb !important;
}

.text-brown {
	color: #795548 !important;
}

.text-cyan {
	color: #00bcd4 !important;
}

.text-blue-grey,
.text-blue-gray {
	color: #607d8b !important;
}

.text-light-green {
	color: #8bc34a !important;
}

.text-indigo {
	color: #3f51b5 !important;
}

.text-khaki {
	color: #b4aa50 !important;
}

.text-lime {
	color: #cddc39 !important;
}

.text-orange {
	color: #ff9800 !important;
}

.text-deep-orange {
	color: #ff5722 !important;
}

.text-pink {
	color: #e91e63 !important;
}

.text-purple {
	color: #9c27b0 !important;
}

.text-deep-purple {
	color: #673ab7 !important;
}

.text-sand {
	color: #fdf5e6 !important;
}

.text-teal {
	color: #009688 !important;
}

.text-light-grey,
.hover-text-light-grey:hover,
.text-light-gray,
.hover-text-light-gray:hover {
	color: #f1f1f1 !important;
}

.text-dark-grey,
.hover-text-dark-grey:hover,
.text-dark-gray,
.hover-text-dark-gray:hover {
	color: #3a3a3a !important;
}

.border-red,
.hover-border-red:hover {
	border-color: #f44336 !important;
}

.border-green,
.hover-border-green:hover {
	border-color: #4caf50 !important;
}

.border-blue,
.hover-border-blue:hover {
	border-color: #2196f3 !important;
}

.border-yellow,
.hover-border-yellow:hover {
	border-color: #ffeb3b !important;
}

.border-white,
.hover-border-white:hover {
	border-color: #fff !important;
}

.border-black,
.hover-border-black:hover {
	border-color: #000 !important;
}

.border-grey,
.hover-border-grey:hover,
.border-gray,
.hover-border-gray:hover {
	border-color: #bbb !important;
}

.mt-10 {
	margin-top: 10px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-50 {
	margin-top: 50px;
}

.mb-50 {
	margin-bottom: 50px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-60 {
	margin-left: 60px;
}

.width-90 {
	width: 90% !important;
}

.width-45 {
	width: 45% !important;
}

.padder {
	padding: 20px;
}

.order-wrapper {
	margin-left: 22px;
	margin-right: 42px;
}

.btn-red {
	width: 152px;
	border: 2px solid;
	border-radius: 7px;
	height: 23px;
	padding: 5px;
}

.btn-upload {
	width: 92px;
	border: 1px solid;
	border-color: rgba(187, 187, 187, 0.79) !important;
	background-color: rgba(229, 229, 229, 0.75);
	border-radius: 7px;
	padding: 5px;
	margin-top: -5px;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.link {
	color: #6fb9c1;
	font-size: 12px;
}

.link:hover {
	color: #17d4e8;
	font-size: 12px;
}

.content {
	color: #000 !important;
	background-color: #f9f9f9 !important;
}

.btn-save {
	border: 1px solid;
	width: 45px;
	border-radius: 2px;
	margin-top: 80%;
	margin-bottom: -20px;
	height: 16px;
	padding: 12px;
	color: white;
	background-color: #d64830;
}

.menuTop {
    /* background: #14b8a6; */
    color: white;
    position: fixed;
    width: 68px;
    left: 0px;
    top: 0px;
    height: 44px;
    z-index: 1000;
}

.headerTop {
	/* border-bottom: 1px solid rgb(206, 204, 204); */
	box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2); 
	background: white;
	position: fixed;
	width: 100%;
	z-index: 1000;
}

.headerTop .avatar {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	user-select: auto;
	height: 50px;
	width: 50px;
}

.headerTopWithoutColor {
	color: white;
	position: fixed;
	width: 100%;
	z-index: 1000;
}

.headerTopWithoutColor .avatar {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	user-select: auto;
	height: auto;
	width: auto;
}

.dropdown-holder {
	padding-top: 12px;
}

.top-dropdown {
	position: absolute;
	left:50px;
	top: 16px !important;
	border-radius: inherit;
	-webkit-border-radius: inherit;
}

.top-dropdown a {
	color: #212529;
}

.dropdown-btn,
.dropdown-btn:active,
dropdown-bt:hover {
	background: transparent !important;
	box-shadow: none !important;
	border: none !important;
	color: #374151 !important;
	padding: 0.5rem 0.75rem !important;
	font-size: 1rem !important;
	line-height: 1.25 !important;
}

.app-notification {
	margin-right: 2rem;
}

.app-notification-icon a,
.app-notification a:active,
.app-notification a:visited {
	color: #374151;
}

.app-notification-icon {
	margin-left: 2.1rem;
	position: relative;
}

.app-notification-icon svg {
	height: 1.3rem;
	width: 1.3rem;
}

.notification-text h5 {
	font-size: 1rem;
	font-weight: 600;
	margin: 0;
}

.notification-avatar {
	width: 50px;
}

.notification-avatar img {
	box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
}

.notification-counter {
	position: absolute;
	top: 0;
	right: 1px;
}

.brand {
	/* margin-left: 10px; */
	display: flex;
}

.brand a {
	padding: 10px 0 10px 0;
}

.brand img {
	height: 50px;
	width: 210px;
	object-fit: contain;
}

.brand-contact img {
	max-width: 180px;
}

.address {
	line-height: 1.5rem;
}

.nav-holder {
}

.all-container {
	padding: 90px 20px 0 20px;
}

.account-dl dt {
	font-weight: 600;
	line-height: 2rem;
}

.account-dl dd {
	min-height: 20px;
}

.avatar-holder img {
	box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
}

/* modal override */

.narcissus_17w311v {
	padding: 0 !important;
}

.narcissus_17w311v .narcissus_86uon7 {
	right: 3px;
	top: 7px;
	left: auto;
}

.narcissus_86uon7 circle {
	fill: none;
}

.narcissus_86uon7 rect {
	fill: black;
}

.select-custom {
	margin: 0;
	min-width: 75px;
}

.btn {
	cursor: pointer !important;
	display: inline-block !important;
	font-weight: normal !important;
	text-align: center !important;
	white-space: nowrap !important;
	vertical-align: middle !important;
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
	border: 1px solid transparent !important;
	padding: 0.5rem 0.75rem !important;
	font-size: 1rem !important;
	line-height: 1.25 !important;
	border-radius: 0.25rem !important;
	-webkit-transition: all 0.15s ease-in-out !important;
	transition: all 0.15s ease-in-out !important;
}

dl.d-flex dd {
	margin-left: 2rem;
}
.d-flex .dropdown {
	display: flex;
}

.btn-icon-holder {
	margin-left: 1rem;
}

.btn-icon {
	padding: 0;
	cursor: pointer;
}

.btn-icon svg {
	vertical-align: top;
	height: 20px;
	width: 20px;
	margin-top: -3px;
}

.react-datepicker__close-icon {
	display: inline;
}

.App {
	text-align: center;
	min-height: 560px !important;
	height: 100%;
	position: absolute;
}

.dropzone .App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.dropzone .App-title {
	font-size: 1.5em;
}

.dropzone .App-intro {
	font-size: large;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.dropzone .form-control {
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
}

.leftColumn {
	width: 12%;
	float: left;
	height: 54rem;
	background-color: #f2f2f2;
}

.rightColumn {
	width: 88%;
	float: right;
	background-color: #ffffff;
}

.airlines-list {
	text-align: left;
	padding-left: 0;
	margin-top: 2%;
	border-top: 0.1px solid #c7c7c7;
	overflow-y: auto;
}

.listStyleCollapse {
	padding-bottom: 3%;
	padding-top: 4.5%;
	padding-left: 5%;
	color: #828282;
	font-family: Lato;
	cursor: pointer;
	margin-left: 5px;
	margin-right: 5px;
}

.staticAirlines-list {
	text-align: left;
	padding-left: 0;
	margin-top: 2%;
	border-top: 0.1px solid #c7c7c7;
	overflow-y: auto;
}

ul.airlines-list li.listStyleCollapse {
	position: relative;
}

ul.airlines-list li.listStyleCollapse:after {
	content: '';
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #817676;
	top: 33%;
}

.custom-pagination {
	position: fixed;
	right: 50px;
	z-index: 105;
}

.custom-pagination .active {
	background-color: #2d373c !important;
	color: #fff;
}

.color-yellow-opac-35 {
	background-color: rgba(255, 175, 0, 0.35);
	color: #000;
}
.color-green-opac-35 {
	background-color: rgba(26, 188, 156, 0.35);
}
.color-blue-opac-35 {
	background-color: rgba(13, 102, 178, 0.35);
}

.virtualized_id_col {
	z-index: 101 !important;
	outline: 0;
	overflow: hidden !important;
}

.css-1py3auy {
	padding: 90px 20px 0 20px;
}

.track-horizontal {
	position: absolute;
	height: 6px;
	right: 2px;
	bottom: 2px;
	left: 2px;
	border-radius: 3px;
}
.track-horizontal .thumb-horizontal {
	position: relative;
	display: block;
	height: 100%;
	cursor: pointer;
	border-radius: inherit;
	background-color: rgba(125, 149, 255, 0.993);
}

.track-view {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	overflow: scroll;
	margin-right: -15px;
	margin-bottom: -17px !important;
}

/* Arrow & Hover Animation */
#more-arrows {
	width: 75px;
	height: 65px;
  }
  #more-arrows:hover polygon {
	fill: #FFF;
	transition: all .2s ease-out;
  }
  #more-arrows:hover polygon.arrow-bottom {
	-webkit-transform: translateY(-18px);
			transform: translateY(-18px);
  }
  #more-arrows:hover polygon.arrow-top {
	-webkit-transform: translateY(18px);
			transform: translateY(18px);
  }
  
  polygon {
	fill: #FFF;
	transition: all .2s ease-out;
  }
  polygon.arrow-middle {
	opacity: 0.75;
  }
  polygon.arrow-top {
	opacity: 0.5;
  }
  
.upload-btn {
	color: white;
	background-color: #2FA3E6;
	padding: 10px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}
.upload-btn:hover {
	background-color: #1c7fb9;
}
.container-fluid {
padding:  0;
height: 100%;
width: 100%;
}
.cred-tab {
	margin: 0 10px;
	border: 1px solid rgb(182, 182, 182);
	padding: 5px;
	border-radius: 5px;
	background-color: white;
}








.ag-header-row-column-group {
	background-color: #2092FFbb;
  }
  
  .ag-header-row-column {
	background-color: #2092FF66;
  }
  
  .ag-header-group-cell:hover,
  .ag-header-cell:hover {
	background-color: #2092FF !important;
  
	* {
	  color: #FFF !important;
	}
  }
  
  .ag-react-container {
	width: 100%;
	height: 100%;
  }
  
  .nav-link {
	.menu-icon {
	  font-size: 24px;
	  color: #71839B;
	}
  
	&.active {
	  background-color: #71839B !important;
	  .menu-icon {
		color: white;
	  }
	}
  }

  .iframe-page {
	width: 100%;
	height: 100%;
  }
  
  div[data-tippy-root] {
	top: 10vh !important;
	left: 10vw !important;
	transform: none !important;
  }

  .link-primary {
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	color: #2092FF !important;
	cursor: pointer;
  }