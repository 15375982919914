.bgWrapper {
  margin: 4px 0;
  height: calc(100% - 8px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.positiveDiff {
  background-color: rgba(70,227,114,.1);
  color: #46e372;
  padding: 4px 8px;
  line-height: 1;
  border-radius: 12px;
}

.negativeDiff {
  background-color: rgba(255,0,92,.19);
  color: #ff005c;
  padding: 4px 8px;
  line-height: 1;
  border-radius: 12px;
}

.diffWrap {
  display: flex;
  align-items: center;
  justify-content: space-around;

  i {
    font-size: 18px;
  }
}