.ant-drawer-body {
    padding: 0;
}
.rowData {
background-color: #dcdbda;
padding-bottom: 70px;
}
.ant-drawer-content-wrapper {
	width: 800px !important;
    margin-top: 70px;
}
.rowData .topInfo {
    border-bottom: 1px solid #f2f0f0;
    padding: 20px 0;
}
.rowData .status {
    display: flex;
    padding: 5px 24px;
}
.rowData .status p {
    margin: 10px;
    font-size: 18px;
}
.rowData .status p span {
    padding: 5px 10px;
    border-radius: 15px;
    color: white;
}
.status p .active {
    font-size: 15px;
    background-color: green;
}
.status p .inactive {
    font-size: 15px;
    background-color: red;
}
.rowData h2 {
    margin: 20px 0;
}
.rowData .table {
    padding: 10px;
    margin: 10px;
}
.ant-drawer-body .ant-spin-spinning {
    position: absolute;
    top: 50%;
    left: 50%;
}