.dropzone ul.airlines-list {
      list-style-type: none;
      text-align: left;
      padding-left: 0;
      margin-top: 2%;
      border-top: 0.1px solid #c7c7c7;
      overflow-y: auto;
  }

.dropzone .listStyle {
      padding-bottom: 6%;
      padding-top: 7.5%;
      color: #828282;
      font-family: Lato;
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;
      border-bottom: 0.1px solid #c7c7c7;
  }

.dropzone .dropdownInnerStyle {
      height: 10rem;
  }

.dropzone .DropFile_span {
      font-family: Segoe;
      font-size: 30px;
      margin: 0;
  }

.dropzone .dropzone {
      padding: 10%;
  }

.dropzone .formArea {
      margin-top: 20px;
      margin-right: 4%;
      margin-left: 4%;
  }

.dropzone .submitDiv {
      height: 3rem;
      background-color: #3a97c9;
      width: 10%;
      right: 0;
      position: absolute;
      margin-right: 9%;
      color: #fff;
      text-align: center;
      border-radius: 10px;
      cursor: pointer;
  }

.dropzone .dropdown {
      margin-top: 2%;
      position: inherit;
      bottom: 0;
      margin-bottom: 5rem;
  }

.dropzone .selectDropClass {
      width: 25%;
      margin-left: 35%;
  }

.dropzone .submitDivDisable {
      height: 3rem;
      background-color: #8bc5e4;
      width: 10%;
      right: 0;
      position: absolute;
      margin-right: 9%;
      color: #fff;
      text-align: center;
      border-radius: 10px;
  }

.dropzone .Select-control {
      height: 45px;
  }

.dropzone .Select-placeholder {
      line-height: 45px;
  }

.dropzone .Select-input>input {
      line-height: 28px;
  }

.dropzone img {
      max-width: 100%;
  }

.dropzone .previewDiv {
      display: inline-block;
      vertical-align: top;
      width: 20%;
      text-align: center;
      margin: 25px auto 10px;
  }