.messageContainer {
  background-color: #dbeeff;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  input,
  textarea {
    font-size: 14px;
    width: 100%;
    height: 100%;
    flex: 1;
    min-width: 400px;
  }
}

.headingText {
  font-size: 14px;
  gap: 4px;
  display: flex;
  align-items: center;

  span {
    width: 30px;
  }
}

.headerContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  position: relative;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}