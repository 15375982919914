.step-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.step-container-info{
  padding: 45px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.stepper-body {
  width: 100%;
  height: 100%;
}

.siteBody {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0px 8px 14px -17px #111;
  width: 100%;
}

.sideBar {
  width: 35%;
  height: 100%;
  padding: 50px 0;
  background-color: #303645;
  color: white;
  overflow-y: auto; /* Add overflow property for scrollbar if content overflows */
}

.stepSummary {
  font-size: 16px;
  width: 80px;
  height: 100%;
  margin-top: 90px;
}

.mainSection {
  width: 75%;
  color: #100e0e;
  text-align: left;
  overflow-y: auto; /* Add overflow property for scrollbar if content overflows */
}

.step {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.step h1 {
  font-size: 30px;
  margin-bottom: 10px;
}

.step p {
  font-size: 16px;
  margin-bottom: 10px;
}

.step-points {
  margin-top: 20px;
  margin-bottom: 20px;
}

.gst-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.gst-button span {
  margin-right: 5px;
}

.insight {
  margin-top: 10px;
  font-size: 12px;
  color: #888;
}

.illustration-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 550px; /* Set the width of the illustration container */
  height: 550px; /* Set the height of the illustration container */
  z-index: -1; /* Send the illustration container to the background */
}

/* Form styles */
.login-form-container {
  max-width: 400px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.primary-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
}

/* Captcha styles */
.captcha {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.captcha input {
  width: 200px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.captcha button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}

.captcha-success {
  color: #28a745;
}

.captcha-invalid {
  color: #dc3545;
}

/* Card styles */
.card {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.center-error-message {
  text-align: center;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
}

#stepper-bubble{
  height: 40px;
  width: 40px;
  font-size: 20px; /* You can adjust the font size to your preference */
color: white;
}

#stepper-label-0{
  margin-top: 10px;
  color: white;
  font-size: 20px;
}

#stepper-label-1{
  margin-top: 10px;
  color: white;
  font-size: 20px;
}
#stepper-label-2{
  margin-top: 10px;
  color: white;
  font-size: 20px;
}
#stepper-label-3{
  margin-top: 10px;
  color: white;
  font-size: 20px;
}

.table-container {
  width: 100%;
  height: 300px;
  overflow: auto; /* This will make the content inside the table container scrollable */
}

.separator{
  height: 40px;
  margin: 20px 0px 20px 0px;
  display:flex;
  align-items: center;
}

.separator .line{
  height: 1px;
  flex: 1;
  background-color: #888;
}

.separator h2{
  padding: 0 0.5rem;
}